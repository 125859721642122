import React from 'react'
import { useState } from 'react';

const useGetAddress = () => {
    const [Address, setAddress] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchAddress = async () => {
        console.log("apaapapapappa");
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        try {
            const response = await fetch(`https://back-end.doggystyless.com/public/api/get-address/${parseInt(user_id)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(data);
            setAddress(data.Address);
            setError(null);
        } catch (error) {
            setAddress();

        } finally {
            setLoading(false);
        }
    };

    return { Address, loading, error, fetchAddress };
}

export default useGetAddress