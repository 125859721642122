import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Search = () => {
    const { query } = useParams();
    const navigate = useNavigate();
    const [productss , setProducts] = useState([]);

    const fetchSearchProducts = async () => {
        try {
            const response = await fetch(`https://back-end.doggystyless.com/public/api/products/search?keyword=${encodeURIComponent(query)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(data);
            setProducts(data.products);
        } catch (error) {
            console.log(error);

        } finally {

        }
    };

    useEffect(() => {
        console.log(query);
        if(query)
        {
            fetchSearchProducts();
        }
        else{
            navigate('/allproducts');
        }

    }, [query]);

    return (
        <>
            <section id='midcontent'>
                {productss.map((product) => (
                    <Link className='link' to={`/productshow/${product.product_code}`}>
                        <div key={product.id} class="card">
                            <div className='image-container'>
                                <img src={`https://back-end.doggystyless.com/public/${product.product_img_main}`} alt="Captivating Elegance Diamond Earrings" />
                            </div>
                            <h3>{product.product_name}</h3>
                            <p class="price">₹{product.product_price * (1 - product.discount_percent / 100)}<span class="price-cut">₹{product.product_price}</span></p>
                            {product.product_quantity === 0 ?
                                (<p class="discount">Out of stock!</p>) :
                                (<p class="discount">Only {product.product_quantity} left!</p>)
                            }
                        </div>
                    </Link>
                ))}
            </section>
        </>
    )
}

export default Search