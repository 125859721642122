import React from 'react'
import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Categorysection from './Categorysection';
import { Person, Cart, Search } from 'react-bootstrap-icons';
import './Header.css'
import { useState } from 'react';
import LoginModal from './LoginModal';
import SignupModal from './SignupModal';
import CartModal from './CartModal';
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

const Header = () => {

    const cookies = new Cookies();
    const cookieUserId = cookies.get('user_id') ? cookies.get('user_id') : 'hello';

    const navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionsVisible, setSuggestionsVisible] = useState(false);

    const [alert, setAlert] = useState(null);

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false); // New state for menu visibility

    // Function to check if token exists in localStorage
    const checkAuthToken = () => {
        const token = localStorage.getItem('token');
        setIsLoggedIn(!!token); // Convert token to boolean
    };

    useEffect(() => {

        checkAuthToken();

        // Add event listener to handle storage changes
        window.addEventListener('storage', checkAuthToken);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('storage', checkAuthToken);
        };
    });

    useEffect(() => {
        console.log('cookieUserId:', cookieUserId); // Debugging line
        if (cookieUserId === 'hello') {
            console.log('Clearing localStorage'); // Debugging line
            localStorage.clear();
        }
    }, [cookieUserId]);

    useEffect(() => {
        if (alert) {
            // Set a timer to clear the alert after 1 second
            const timer = setTimeout(() => {
                setAlert(null);
            }, 1000);

            // Clean up the timer if the component unmounts or alert changes
            return () => clearTimeout(timer);
        }
    }, [alert]);

    const handleLogout = () => {
        // Remove token from localStorage and update state
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        cookies.remove('user_id');
        setIsLoggedIn(false);
        Swal.fire({
            title: 'Logout Successful!',
          })
    };

    const [loginShow, setLoginShow] = useState(false);
    const [signupShow, setSignupShow] = useState(false);
    const [showCart, setShowCart] = useState(false);

    const handleClose = () => {
        setLoginShow(false);
        setSignupShow(false);
        setShowCart(false);
    };
    const handleShowLogin = () => setLoginShow(true);
    const handleShowSignup = () => setSignupShow(true);

    const handleShowCart = async() => {

        const token = localStorage.getItem('token');

        try {
            const response = await fetch('https://back-end.doggystyless.com/public/api/user-check', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify({ user_id: localStorage.getItem('user_id') }),
            });
            const data = await response.json();
            setShowCart(true)

        } catch (error) {
            handleShowLogin();
        }
    };

    const toggleMenu = () => {
        setMenuOpen(prev => !prev);
    };

    const fetchSuggestions = async (query) => {
        try {
            const response = await fetch(`https://back-end.doggystyless.com/public/api/products/search?keyword=${encodeURIComponent(query)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setSuggestions(data.products);
            setSuggestionsVisible(true);
        } catch (error) {
            console.log(error);

        } finally {

        }
    };

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
        fetchSuggestions(e.target.value);
    };

    const handleBlur = () => {
        setSuggestionsVisible(false);
    };

    const handleFocus = () => {
        setSuggestionsVisible(true);
    };
    const handleSearchSubmit = (e) => {
        e.preventDefault();
        const trimmedQuery = searchQuery.trim();
        if (trimmedQuery) {
            setSearchQuery('');
            setSuggestions([]);
            navigate(`/search/${trimmedQuery}`);
        }
        else {
            setSearchQuery('');
            setSuggestions([]);
            navigate('/allproducts')
        }
    }

    return (
        <>
            <div id='divaftermedialogo'><div className="logo">Jwellery</div></div>
            <div className="header">
                <div id='logot' className="logo">Jwellery</div>
                <div onClick={toggleMenu} id='ham'>☰</div>
                <div className="phonecart" onClick={handleShowCart}>
                    <Cart size={15} />
                    <span style={{ marginLeft: '4px' }} >CART</span>
                </div>
                {menuOpen && (
                    <div className="menu-overlay">
                        <button className="close-button" onClick={toggleMenu}>✖</button>
                        <div className="menu-content">
                            <div className="navphone">
                                <div className="nav-itemphone">
                                    <Person style={{ marginLeft: '3rem' }} size={20} />
                                    <span>
                                        <Dropdown>
                                            <Dropdown.Toggle className="custom-dropdown-toggle" id="dropdown-basic">
                                                ACCOUNT
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {isLoggedIn ? (
                                                    <>
                                                        <Dropdown.Item id='accountdisc'>My Account</Dropdown.Item>
                                                        <Dropdown.Item id='logutbut' onClick={handleLogout}>Logout</Dropdown.Item>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Dropdown.Item id='accountdisc'>My Account</Dropdown.Item>
                                                        <Dropdown.Item id='accountdisc2'>Login to access your account</Dropdown.Item>
                                                        <div id='x'>
                                                            <Dropdown.Item id='lsbut' onClick={handleShowLogin} as="a">Login</Dropdown.Item>
                                                            <Dropdown.Item id='lsbut' onClick={handleShowSignup} as="a">Sign up</Dropdown.Item>
                                                        </div>
                                                    </>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </span>
                                </div>
                                <div className="nav-itemphone">
                                    <Link onClick={toggleMenu} className='link' id='aboutuslink' to={'/aboutus'}><span>ABOUT US</span></Link>
                                </div>
                                <div className="nav-itemphone">
                                    <Link onClick={toggleMenu} className="link" id='aboutuslink' to={'/tac'}><span>TERMS AND CONDITIONS</span></Link>
                                </div>
                                <div className="nav-itemphone">
                                    <span>CONTACT US</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div class="searchcontainer">
                    <div class="search-bar">
                        <form action="#" onSubmit={handleSearchSubmit}>
                            <input type="text" class="search-input" placeholder="Search..." value={searchQuery}
                                onChange={handleInputChange} />
                            <button id='searchbutton' type='submit'><Search size={20} /></button>
                        </form>
                        {suggestions.length > 0 && (
                            <div className="suggestions-box">
                                {suggestionsVisible && suggestions.map((suggestion, index) => (
                                    <div key={index} className="suggestion-item" onClick={() => setSearchQuery(suggestion.product_name)}>
                                        {suggestion.product_name}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="nav">
                    <div className="nav-item">
                        <Link id='aboutuslink' to={'/aboutus'}><span>ABOUT US</span></Link>
                    </div>
                    <div className="nav-item">
                        <span>CONTACT US</span>
                    </div>
                    <div className="nav-item">
                        <Person size={15} />
                        <span>
                            <Dropdown>
                                <Dropdown.Toggle className="custom-dropdown-toggle" id="dropdown-basic">
                                    ACCOUNT
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {isLoggedIn ? (
                                        <>
                                            <Dropdown.Item id='accountdisc'>My Account</Dropdown.Item>
                                            <Dropdown.Item id='logutbut' onClick={handleLogout}>Logout</Dropdown.Item>
                                        </>
                                    ) : (
                                        <>
                                            <Dropdown.Item id='accountdisc'>My Account</Dropdown.Item>
                                            <Dropdown.Item id='accountdisc2'>Login to access your account</Dropdown.Item>
                                            <div id='x'>
                                                <Dropdown.Item id='lsbut' onClick={handleShowLogin} as="a"  >Login</Dropdown.Item>
                                                <Dropdown.Item id='lsbut' onClick={handleShowSignup} as="a"  >Sign up</Dropdown.Item>
                                            </div>
                                        </>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </span>
                    </div>

                    <div className="nav-item" onClick={handleShowCart}>
                        <Cart size={15} />
                        <span style={{ marginLeft: '4px' }} >CART</span>
                    </div>
                </div>
            </div>
            <Categorysection />
            <LoginModal show={loginShow} onClose={handleClose} />
            <SignupModal show={signupShow} onClose={handleClose} />
            <CartModal show={showCart} onClose={handleClose} />

        </>
    )
}

export default Header