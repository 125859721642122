import React from 'react'
import './Admin.scss'
import { Link } from 'react-router-dom'

const Admin = () => {
    return (
        <div id='adminlinks'>
            <Link className='adminlinks' to={'addproducts'}>Add Products</Link>
            <Link className='adminlinks' to={'addcategories'}>Add Categories</Link>
            <Link className='adminlinks' to={'addcarousel'}>Add Carousel</Link>
            <Link className='adminlinks' to={'showusers'}>Show All Users</Link>
            <Link className='adminlinks' to={'adminaboutus'}>About Us</Link>
            <Link className='adminlinks' to={'admintac'}>Terms and Conditions</Link>
        </div>

    )
}

export default Admin