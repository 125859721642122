// src/components/LoginModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Carousel } from 'react-bootstrap';
import './Cart.scss'
import useCartContant from './useCartContant';
import { useNavigate } from 'react-router-dom';


const CartModal = ({ show, onClose }) => {

    const navigate = useNavigate();

    const { cartproducts, loading, error, fetchCartProducts } = useCartContant();

    useEffect(() => {
        if (show) {
            fetchCartProducts();
        }
    }, [show]);

    // Initialize state for quantity

    // Handler function to increment quantity
    const updateQuantity = async (productCode, action) => {
        console.log(productCode);
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') || '';

        try {
            const response = await fetch(`https://back-end.doggystyless.com/public/api/cart/quantity`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ user_id: parseInt(user_id), product_code: productCode, action }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(data);
            fetchCartProducts();
        } catch (error) {
        }
    };

    const removeProduct = async (e) => {
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';

        try {
            const response = await fetch(`https://back-end.doggystyless.com/public/api/cart/delete`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify({ user_id: parseInt(user_id), product_code: e.target.id}),
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            fetchCartProducts();
        } catch (error) {

        } finally {

        }
    }

    const removeProductall = async (e) => {

        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';

        try {
            const response = await fetch(`https://back-end.doggystyless.com/public/api/cart/delete-all`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify({ user_id: parseInt(user_id) }),
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            fetchCartProducts();
        } catch (error) {

        } finally {

        }
    }

    const handleCheckout = () =>{
        onClose();
        navigate('/checkoutpage');
    }

    return (
        <Modal show={show} onHide={onClose} className="custom-modal">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className='modalbody'>
                <div class="headercart">
                    <h1>Shopping Cart</h1>
                    <p>{cartproducts.cart_items.length} Items</p>
                </div>

                <div class="container">
                    <div class="left-col">
                        {cartproducts.cart_items.map((cartproduct, index) => (

                            <div key={index} class="product-item">
                                <img className='img-thumbnail' src={`https://back-end.doggystyless.com/public/${cartproduct.product.product_img_main}`} alt="No Items" class="product-img" />
                                <div class="product-info">
                                    <div class="product-title">{cartproduct.product.product_name}</div>
                                    <div class="product-details">
                                        <span>Price : {cartproduct.total_price}</span>
                                    </div>
                                    <div id='quantity'>
                                        <span>Quantity</span>
                                        <input
                                            type="number"
                                            value={cartproduct.quantity}
                                            readOnly
                                        />
                                        <button onClick={() => updateQuantity(cartproduct.product.product_code, 'increment')}>+</button>
                                        <button onClick={() => updateQuantity(cartproduct.product.product_code, 'decrement')}>-</button>
                                    </div>
                                    <div id='quantity_adjusted'>{cartproduct.adjustment_message}</div>
                                </div>
                                <div><button id={cartproduct.product.product_code} onClick={removeProduct} className='btn btn-danger'>Remove</button></div>
                            </div>

                        ))}
                    </div>

                    <div class="right-col">

                        {cartproducts.cart_items.map((cartproduct, index) => (
                            <div key={index}>
                                <div class="price">₹ {cartproduct.product_price * (1 - cartproduct.discount_percent / 100)} * {cartproduct.quantity}  <span id='discountcc'>[Price including discount <span>{cartproduct.discount_percent}%</span>] </span></div>
                                <div class="total">{cartproduct.total_price}</div>
                            </div>
                        ))}
                        <div id='border'></div>

                        <div class="total"> Sub Total : ₹ {cartproducts.total_cart_value}</div>
                        <div><button onClick={removeProductall} className='btn btn-danger'>Clear all Cart</button></div>
                        <div id='checkoutdiv'><button onClick={handleCheckout} id='checkout' className='btn btn-success'>Checkout</button></div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CartModal;
