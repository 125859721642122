import React, { useEffect, useState } from 'react';
import useCartContant from '../Mainpage/useCartContant';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Select from 'react-select';
import { Country, State, City } from 'country-state-city';
import useGetAddress from './useGetAddress';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const CheckOut = () => {

    const navigate = useNavigate();

    const { cartproducts, loading, error, fetchCartProducts } = useCartContant();
    const { Address, load, err, fetchAddress } = useGetAddress();
    const [phone, setPhone] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [update, setUpdate] = useState(false);
    const [formData, setFormData] = useState({
        user_id: localStorage.getItem('user_id'),
        name: '',
        address: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
        contact_no: ''
    });

    const check = async() =>{

        const token = localStorage.getItem('token');

        try {
            const response = await fetch('https://back-end.doggystyless.com/public/api/user-check', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify({ user_id: localStorage.getItem('user_id') }),
            });
            const data = await response.json();

        } catch (error) {
            navigate('/');
        }
    }
    useEffect(() => {
        check();
        fetchCartProducts();
        fetchAddress();

    }, []);

    useEffect(() => {
        if (Address) {
            setUpdate(true);
            setFormData({
                ...formData,
                name: Address.name || '',
                address: Address.address || '',
                city: Address.city || '',
                state: Address.state || '',
                country: Address.country || '',
                pincode: Address.pincode || '',
                contact_no: Address.contact_no || ''
            });
            setPhone(Address.contact_no || '');

            const countryOption = Country.getAllCountries().find(country => country.name === Address.country);
            setSelectedCountry(countryOption ? { value: countryOption.isoCode, label: countryOption.name } : null);

            if (countryOption) {
                const statesList = State.getStatesOfCountry(countryOption.isoCode);
                setStates(statesList.map(state => ({
                    value: state.isoCode,
                    label: state.name
                })));

                const stateOption = statesList.find(state => state.name === Address.state);
                setSelectedState(stateOption ? { value: stateOption.isoCode, label: stateOption.name } : null);

                if (stateOption) {
                    const citiesList = City.getCitiesOfState(countryOption.isoCode, stateOption.isoCode);
                    setCities(citiesList.map(city => ({
                        value: city.name,
                        label: city.name
                    })));

                    const cityOption = citiesList.find(city => city.name === Address.city);
                    setSelectedCity(cityOption ? { value: cityOption.isoCode, label: cityOption.name } : null);
                }
            }
        }
        else {
            setUpdate(false);
            setFormData({
                ...formData,
                name: '',
                address: '',
                city: '',
                state: '',
                country: '',
                pincode: '',
                contact_no: ''
            });
            setPhone('');
            setSelectedCity(null);
            setSelectedState(null);
            setSelectedCountry(null);
            setStates([]);
            setCities([]);

        }
    }, [Address]);

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        const statesList = State.getStatesOfCountry(selectedOption.value);
        setStates(statesList.map(state => ({
            value: state.isoCode,
            label: state.name
        })));
        setCities([]);
        setFormData(prevData => ({
            ...prevData,
            country: selectedOption.label
        }));
    };

    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        const citiesList = City.getCitiesOfState(selectedCountry.value, selectedOption.value);
        setCities(citiesList.map(city => ({
            value: city.name,
            label: city.name
        })));
        setFormData(prevData => ({
            ...prevData,
            state: selectedOption.label
        }));
    };

    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
        setFormData(prevData => ({
            ...prevData,
            city: selectedOption.label
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handlePhoneChange = (value) => {
        setPhone(value);
        setFormData(prevData => ({
            ...prevData,
            contact_no: value
        }));
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        // Example API endpoint
        const apiEndpoint = 'https://back-end.doggystyless.com/public/api/address/store';

        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Success:', result);
                fetchAddress();
                Swal.fire({
                    title: 'Address Added Successful!',
                })
                // Handle success (e.g., redirect or show a success message)
            } else {
                const error = await response.json();
                console.error('Error:', error);
                // Handle error (e.g., show an error message)
            }
        } catch (error) {
            console.error('Network error:', error);
            // Handle network error (e.g., show an error message)
        }
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        // Example API endpoint
        const apiEndpoint = 'https://back-end.doggystyless.com/public/api/address/delete';

        try {
            const response = await fetch(apiEndpoint, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify({ user_id: user_id })
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Success:', result);
                fetchAddress();
                setUpdate(false);
                Swal.fire({
                    title: 'Address Deleted Successful!',
                })
                // Handle success (e.g., redirect or show a success message)
            } else {
                const error = await response.json();
                console.error('Error:', error);
                // Handle error (e.g., show an error message)
            }
        } catch (error) {
            console.error('Network error:', error);
            // Handle network error (e.g., show an error message)
        }
    }

    const leftColClassName = update ? 'none' : 'left-col';
    const leftColClass = update ? 'left-col' : 'none';

    if (load) {
        return <div className="loader">Loading...</div>;
    }

    if (err) {
        return <div className="error">Error: {err.message}</div>;
    }


    return (
        <div className="container" style={{ padding: '16px' }}>
            {/* Shipping Information */}
            <div className={leftColClassName}>
                <section className="checkout-shipping-info">
                    <h2 className="section-title">Shipping Information</h2>
                    <form className="shipping-form" onSubmit={handleSubmit}>
                        <label htmlFor="name" className="form-label">Full Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-input"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />

                        <label htmlFor="address" className="form-label">Address :</label>
                        <input
                            type="text"
                            id="address"
                            name="address"
                            className="form-input"
                            value={formData.address}
                            onChange={handleInputChange}
                            required
                        />

                        <label htmlFor="country" className="form-label">Country:</label>
                        <Select
                            id="country"
                            name="country"
                            options={Country.getAllCountries().map(country => ({
                                value: country.isoCode,
                                label: country.name
                            }))}
                            className="form-select"
                            onChange={handleCountryChange}
                            value={selectedCountry}
                        />

                        <label htmlFor="state" className="form-label">State:</label>
                        <Select
                            id="state"
                            name="state"
                            options={states}
                            className="form-select"
                            onChange={handleStateChange}
                            value={selectedState}
                        />

                        <label htmlFor="city" className="form-label">City:</label>
                        <Select
                            id="city"
                            name="city"
                            options={cities}
                            className="form-select"
                            onChange={handleCityChange}
                            value={selectedCity}
                        />


                        <label htmlFor="pincode" className="form-label">Pincode/ZIP Code:</label>
                        <input
                            type="text"
                            id="pincode"
                            name="pincode"
                            className="form-input"
                            value={formData.pincode}
                            onChange={handleInputChange}
                            required
                        />

                        <label htmlFor="contact_no" className="form-label">Contact Number :</label>
                        <PhoneInput
                            placeholder="Enter phone number"
                            value={phone}
                            className="form-input"
                            onChange={handlePhoneChange}
                        />
                        <button type="submit" className="add_address_button">Add Address</button>
                    </form>
                </section>
            </div>
            <div className={leftColClass}>
                <section className="checkout-shipping-info">
                    <h2 className="section-title">Shipping Information</h2>
                    <div className="shipping-info">
                        <div className="info-item">
                            <strong>Full Name:</strong> {formData.name}
                        </div>
                        <div className="info-item">
                            <strong>Address:</strong> {formData.address}
                        </div>
                        <div className="info-item">
                            <strong>City:</strong> {formData.city}
                        </div>
                        <div className="info-item">
                            <strong>State:</strong> {formData.state}
                        </div>
                        <div className="info-item">
                            <strong>Country:</strong> {formData.country}
                        </div>
                        <div className="info-item">
                            <strong>Pincode/ZIP Code:</strong> {formData.pincode}
                        </div>
                        <div className="info-item">
                            <strong>Contact Number:</strong> {phone}
                        </div>
                        <button
                            type="button"
                            onClick={() => setUpdate(false)}
                            className="add_address_button">
                            Update Address
                        </button>
                        <button
                            type="button"
                            onClick={handleDelete}
                            className="add_address_button">
                            Delete Address
                        </button>
                    </div>
                </section>
            </div>

            <div className="right-col">
                {/* Order Summary */}
                <section className="checkout-order-summary">
                    <h2 className="section-title">Order Summary</h2>
                    {cartproducts.cart_items.map((cartproduct, index) => (
                        <div key={index}>
                            <ul className="order-list">
                                <div className="total">{cartproduct.product_name}</div>
                                <div className="price">
                                    ₹ {cartproduct.product_price * (1 - cartproduct.discount_percent / 100)} * {cartproduct.quantity} =
                                    <span className="total">{cartproduct.total_price}</span>
                                    <span id='discountcc'>
                                        [Price including discount <span>{cartproduct.discount_percent}%</span>]
                                    </span>
                                </div>
                            </ul>
                        </div>
                    ))}
                    <div id='border'></div>
                    <div className="total"> Sub Total : ₹ {cartproducts.total_cart_value}</div>
                </section>

                {/* Payment Options */}
                <section className="checkout-payment-options">
                    <button type="button" id="proceed-pay" className="proceed-pay-button">Proceed to Pay</button>
                </section>
            </div>
        </div>
    );
};

export default CheckOut;
