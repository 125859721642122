import React, { useState , useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './AddAboutUs.scss'; // Ensure this file exists for your styling
import ShowAboutUs from './ShowAboutUs';

const AddAboutUs = () => {
    const [content, setContent] = useState('');
    const { aboutus, load, err, fetchaboutus } = ShowAboutUs();

    useEffect(() => {
        if(aboutus && aboutus.about_us)
        {
            setContent(aboutus.about_us);
        }
    }, [aboutus]); // Depend on fetchaboutus if it's a function

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.target;
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        // Handle form submission
        try {
            const response = await fetch(`https://back-end.doggystyless.com/public/api/admin/about-us/${user_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify({ user_id: parseInt(user_id), about_us: content }),
            });
            const result = await response.json();
            console.log('about us added successfully:', result);
            alert("Added Successful");
            form.reset();
        } catch (error) {
            console.error('Error adding product:', error);
        }
        console.log('About Us Content:', content);
    };

    return (
        <div id='adminformdivau'>
            <form id='aboutusform' onSubmit={handleSubmit}>
                <label>About Us Content</label>
                <div className='ckeditor-container'>
                    <CKEditor
                        editor={ClassicEditor}
                        data={content}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setContent(data);
                        }}
                    />
                </div>
                <button className='adminbutton' type='submit'>Add About Us</button>
            </form>
        </div>
    );
};

export default AddAboutUs;
