import React from 'react'
import { Link } from 'react-router-dom'

const Filtercontroller = () => {
  return (
    <div id='filtercontroller'>
        <div><Link className='link' to={'/'}>Home</Link></div>
        <div id='filter'>Filter</div>
    </div>
  )
}

export default Filtercontroller