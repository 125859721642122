import React from 'react'
import { useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import LoginModal from '../Mainpage/LoginModal';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const AddReviewModal = ({ show, onClose, product_id }) => {

    const [loginShow, setLoginShow] = useState(false);

    const handleClose = () => {
        setLoginShow(false);
    };
    const handleShowLogin = () => setLoginShow(true);

    const [review, setreview] = useState('');
    const [stars, setstars] = useState(1);


    const handleSubmitreview = async (event) => {
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        event.preventDefault();

        try {
            console.log({ id: parseInt(user_id), product_id: product_id, rating: parseInt(stars), review: review });
            const response = await fetch('https://back-end.doggystyless.com/public/api/reviews', { // Replace with your API endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify({ id: parseInt(user_id), product_id: product_id, rating: parseInt(stars), review: review }),
            });
            const data = await response.json();
            // Store token and user ID in local storage
            onClose();

        } catch (error) {
            onClose();
            localStorage.clear();
            cookies.remove('user_id');
            handleShowLogin();
            console.error('Error adding category:', error);
        }
    };
    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="popupcontainer">
                        <div className="left-side">
                            <h1>Review</h1>

                            <form className='reviewform' onSubmit={handleSubmitreview}>
                                <textarea
                                    class="styled-textarea"
                                    type="text"
                                    placeholder='Write a Review'
                                    value={review}
                                    onChange={(e) => {
                                        setreview(e.target.value)
                                    }}
                                />
                                <div>
                                    <span id='1'
                                        onClick={(e) => {
                                            setstars(e.target.id)
                                        }}
                                        className= {1<=stars?"starx":"starr"}>★</span>

                                        <span id='2'
                                        onClick={(e) => {
                                            setstars(e.target.id)
                                        }}
                                        className= {2<=stars?"starx":"starr"}>★</span>

                                        <span id='3'
                                        onClick={(e) => {
                                            setstars(e.target.id)
                                        }}
                                        className= {3<=stars?"starx":"starr"}>★</span>

                                        <span id='4'
                                        onClick={(e) => {
                                            setstars(e.target.id)
                                        }}
                                        className= {4<=stars?"starx":"starr"}>★</span>

                                        <span id='5'
                                        onClick={(e) => {
                                            setstars(e.target.id)
                                        }}
                                        className= {5<=stars?"starx":"starr"}>★</span>
                                </div>
                                <div id='butdiv'>
                                    <button className='loginsignupbutton' type='submit'>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <LoginModal show={loginShow} onClose={handleClose} />
        </>
    )
}

export default AddReviewModal