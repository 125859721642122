import './App.scss';
import Admin from './components/Admin/Admin';
import Addproducts from './components/Admin/Addproducts';
import Addcategories from './components/Admin/Addcategories';
import Showusers from './components/Admin/Showusers';
import Header from './components/Mainpage/Header';
import { Adminheader } from './components/Admin/Adminheader';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Content/Home';
import Landingpage from './components/Landingpage/Landingpage'
import AdminRoutes from './components/Private_routes/AdminRoutes';
import Addcarousel from './components/Admin/Addcarousel';
import Productshow from './components/Content/Productshow';
import Footer from './components/Footer/Footer';
import Showallreviews from './components/Content/Showallreviews';
import AddAboutUs from './components/Admin/AddAboutUs';
import AddTAC from './components/Admin/AddTAC';
import AboutUs from './components/Content/AboutUs';
import Search from './components/Search/Search';
import TAC from './components/Content/TAC';
import CheckOut from './components/CheckOut/CheckOut';
import CheckOutHeader from './components/CheckOut/CheckOutHeader';
import Check from './components/Private_routes/Check';
function App() {
  const location = useLocation();
  const isAdminPage = location.pathname.startsWith('/admin');
  const isCheckOutPage = location.pathname.startsWith('/checkoutpage');
  return (
    <>
      {!isAdminPage && !isCheckOutPage && <Header />}
      {isAdminPage && <Adminheader />}
      {isCheckOutPage && <CheckOutHeader />}
      <Routes>
        <Route path='/' element={<Landingpage />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/checkoutpage/*' element={<Check />}>
          <Route path='' element={<CheckOut />} />
        </Route>
        <Route path='/tac' element={<TAC />} />
        <Route path='/productshow/:product_code' element={<Productshow />} />
        <Route path='/allproducts' element={<Home />} />
        <Route path='/category/:category_code' element={<Home />} />
        <Route path='/reviewshow/:product_code' element={<Showallreviews />} />
        <Route path='/search/:query' element={<Search />} />
        <Route path='/admin/*' element={<AdminRoutes />} >
          <Route path='' element={<Admin />} />
          <Route path='adminaboutus' element={<AddAboutUs />} />
          <Route path='admintac' element={<AddTAC />} />
          <Route path='addcarousel' element={<Addcarousel />} />
          <Route path='addproducts' element={<Addproducts />} />
          <Route path='addcategories' element={<Addcategories />} />
          <Route path='showusers' element={<Showusers />} />
          {/* Add more admin sub-routes as needed */}
        </Route>
      </Routes>
      {!isAdminPage && !isCheckOutPage && <Footer />}
    </>
  );
}

export default App;
