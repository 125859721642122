import { useState } from 'react';

const useProductshow = (product_code) => {
    const [singleproduct, setsingleproduct] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchSingleProduct = async () => {
        const queryParams = new URLSearchParams({product_code:product_code}).toString();
        try {
            const response = await fetch(`https://back-end.doggystyless.com/public/api/products?${queryParams}`, {
                method: 'GET',
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setsingleproduct(data.product);
            setError(null);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return { singleproduct, loading, error, fetchSingleProduct };
}

export default useProductshow