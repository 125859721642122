import React, { useState } from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';

function Footer() {
  // State to manage visibility of each footer column
  const [visibleColumns, setVisibleColumns] = useState({
    usefulLinks: false,
    information: false,
    contactUs: false,
    appDownload: false
  });

  // Function to toggle visibility of a specific column
  const toggleColumnVisibility = (column) => {
    setVisibleColumns(prevState => ({
      ...prevState,
      [column]: !prevState[column]
    }));
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className={`footer-column ${visibleColumns.usefulLinks ? 'active' : ''}`}>
          <h3 className="footer-title" onClick={() => toggleColumnVisibility('usefulLinks')}>
            Terms and Conditions
          </h3>
          <Link className="footer-link" id='aboutuslink' to={'/tac'}><span>Terms and Conditions</span></Link>
        </div>
        <div className={`footer-column ${visibleColumns.information ? 'active' : ''}`}>
          <h3 className="footer-title" onClick={() => toggleColumnVisibility('information')}>
            Information
          </h3>
          <a href="#" className="footer-link">Careers</a>
          <a href="#" className="footer-link">Blog</a>
          <a href="#" className="footer-link">Offers & Contest Details</a>
          <a href="#" className="footer-link">Help & FAQs</a>
          <a href="#" className="footer-link">About Jwellery</a>
        </div>
        <div className={`footer-column ${visibleColumns.contactUs ? 'active' : ''}`}>
          <h3 className="footer-title" onClick={() => toggleColumnVisibility('contactUs')}>
            Contact Us
          </h3>
          <a href="mailto:info@Jwellery.com" className="footer-link"><i className="fas fa-envelope"></i> Write to Us</a>
          <a href="tel:+18002660123" className="footer-link"><i className="fas fa-phone-alt"></i> 1800-266-0123</a>
          <a href="#" className="footer-link"><i className="fas fa-comments"></i> Chat with Us</a>
        </div>
        <div className={`footer-column ${visibleColumns.appDownload ? 'active' : ''}`}>
          <h3 className="footer-title" onClick={() => toggleColumnVisibility('appDownload')}>
            Download the Jwellery App Now
          </h3>
          <div className="qr-code">
            <img src="qr-code.png" alt="QR Code"/>
          </div>
          <a href="#" className="app-store"><img src="app-store-logo.png" alt="App Store Logo"/></a>
          <a href="#" className="google-play"><img src="google-play-logo.png" alt="Google Play Logo"/></a>
        </div>
      </div>
      <div className="copyright">
        &copy; 2023 Jwellery. All Rights Reserved.
      </div>
    </footer>
  );
}

export default Footer;
