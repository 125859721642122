import React from 'react'
import useCategories from '../Admin/useCategories'
import { Link } from 'react-router-dom';

const Categorysection = () => {
    const {categories , loding , error , fetchcategories} = useCategories();
  return (
    <div id='categories'>
      <span><Link className='link' to={`/allproducts`}>All Products</Link></span>
        {categories.map((category)=>(
            <span key={category.category_code}><Link className='link' to={`/category/${category.category_code}`}>{category.category_name}</Link></span>
        ))}
    </div>
  )
}

export default Categorysection