import React from 'react'
import { useState } from 'react';
import useCarousel from './useCarousel';

const Addcarousel = () => {

    const { carousels, loading, errorc, fetchCarousel } = useCarousel();

    const [formData, setFormData] = useState({
        image: null,
        user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : null
    });

    const handleChange = (event) => {
        const { name, type, files } = event.target;

        if (type === 'file') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: files[0], // Handle only the first file
            }));
        }
    };

    const handleSubmit = async (event) => {
        const form = event.target;
        event.preventDefault();
        const token = localStorage.getItem('token');
        // Create a new FormData object
        const data = new FormData();

        // Append image files only if they exist
        data.append('image', formData.image || '');

        // Append delivery time or empty string if not provided
        data.append('user_id', formData.user_id || '');
        console.log(formData);

        try {
            const response = await fetch('https://back-end.doggystyless.com/public/api/carousels', {
                method: 'POST',
                body: data, // FormData automatically sets the correct Content-Type header
                headers: {
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
            });
            // console.log(response);
            const result = await response.json();
            console.log('Product added successfully:', result);
            form.reset();
        } catch (error) {
            console.error('Error adding product:', error);
        }
    };

    const deletecarousel = async (event) => {
        const id = event.target.id;
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        try {
          await fetch(`https://back-end.doggystyless.com/public/api/carousels/${id}`, { // Replace with your API endpoint
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}` // Using Bearer token authentication
            },
            body: JSON.stringify({ user_id: user_id }),
          });
          fetchCarousel(); // Refresh categories
        } catch (error) {
          console.error('Error adding category:', error);
        }
      }


    return (
        <div>
            <div id='adminformdiv'>
                <form id='addcarouselform' onSubmit={handleSubmit}>

                    <label>Upload Image</label>
                    <input
                        className='admininput'
                        name='image'
                        type="file"
                        onChange={handleChange}
                    />
                    <button className='adminbutton' type='submit'>Add Carousel</button>
                </form>
            </div>
            <table className='table'>
        <thead>
          <tr>
            <th>Categories</th>
          </tr>
        </thead>
        <tbody>
          {carousels.map((carousel,index) => (
            <tr key={index}>
              <td>{carousel.id}</td>
              <td><img className='img-thumbnail' src={`https://back-end.doggystyless.com/public/${carousel.image_path}`} alt="" /></td>
              <td><button className='adminbutton' id={carousel.id} onClick={deletecarousel}>Delete</button></td>
            </tr>
          ))}
        </tbody>
      </table>
        </div>
    )
}

export default Addcarousel