import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import useCategories from './useCategories';
import useProductshow from '../Content/useProductshow';
const UpdateProductModal = ({ show, onClose, product_code }) => {

    const { singleproduct, load, err, fetchSingleProduct } = useProductshow(product_code);

    useEffect(() => {
        if (show) {
            if (product_code) {

                fetchSingleProduct();
            }
        }
    }, [show]);

    useEffect(() => {
        console.log(singleproduct);
        if (singleproduct) {
            setFormData({
                productName: singleproduct.product_name || '',
                productCategory: singleproduct.product_category || '',
                productQuantity: singleproduct.product_quantity || '',
                productDesc: singleproduct.product_desc || '',
                productPrice: singleproduct.product_price || '',
                productMainImage: null, // Reset file inputs
                productImage1: null,
                productImage2: null,
                productImage3: null,
                product_delivery_time: singleproduct.product_delivery_time || '',
                discount_percent: singleproduct.discount_percent || '',
                discount_time: singleproduct.discount_time || '',
                user_id: localStorage.getItem('user_id') || '',
                product_carat: singleproduct.product_carat || '',
                product_weight: singleproduct.product_weight || '',
                product_code : product_code || ''
            });
        }
    }, [singleproduct]);

    const { categories, loading, error, fetchCategories } = useCategories();

    const [formData, setFormData] = useState({
        productName: '',
        productCategory: '',
        productQuantity: '',
        productDesc: '',
        productPrice: '',
        productMainImage: null,
        productImage1: null,
        productImage2: null,
        productImage3: null,
        product_delivery_time: null,
        discount_percent: null,
        discount_time: null,
        user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : null,
        product_carat: null,
        product_weight: null,
        product_code : product_code
    });

    const handleChange = (event) => {
        const { name, value, type, files } = event.target;

        if (type === 'file') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: files[0], // Handle only the first file
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.target;
        const token = localStorage.getItem('token');
        // Create a new FormData object
        const data = new FormData();
        data.append('product_name', formData.productName || '');
        data.append('product_category', formData.productCategory || '');
        data.append('product_quantity', formData.productQuantity || '');
        data.append('product_desc', formData.productDesc || '');
        data.append('product_price', formData.productPrice || '');

        // Append image files only if they exist
        data.append('product_img_main', formData.productMainImage || '');
        data.append('product_img_1', formData.productImage1 || '');
        data.append('product_img_2', formData.productImage2 || '');
        data.append('product_img_3', formData.productImage3 || '');

        // Append delivery time or empty string if not provided
        data.append('product_delivery_time', formData.product_delivery_time || '');
        data.append('discount_percent', formData.discount_percent || '');
        data.append('discount_time', formData.discount_time || '');
        data.append('product_carat', formData.product_carat || '');
        data.append('product_weight', formData.product_weight || '');
        data.append('user_id', formData.user_id || '');
        data.append('product_code', formData.product_code || '');
        console.log(data);

        try {
            const response = await fetch('https://back-end.doggystyless.com/public/api/products-update', {
                method: 'POST',
                body: data, // FormData automatically sets the correct Content-Type header
                headers: {
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
            });
            // console.log(response);
            const result = await response.json();
            // Handle success (e.g., show a message or reset the form)
            form.reset();
            onClose();
            alert('Product Updated successfully');
        } catch (error) {
            console.error('Error adding product:', error);
        }
    };

    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    Update Form for {product_code}
                </Modal.Header>
                <Modal.Body>
                    <div id='adminformdiv'>
                        <form id='addproductsform' onSubmit={handleSubmit}>
                            <label>Enter Artical Name</label>
                            <input
                                className='admininput'
                                type="text"
                                name='productName'
                                placeholder='Product Name'
                                required
                                value={formData.productName}
                                onChange={handleChange}
                            />
                            <label>Choose a Category:</label>
                            <select
                                className='admininput'
                                id="productCategory"
                                name="productCategory"
                                required
                                value={formData.productCategory}
                                onChange={handleChange}
                            >
                                <option value="">Select a Category</option>
                                {categories.map((category) => (
                                    <option key={category.category_code} value={category.category_code}>{category.category_code}</option>
                                ))}
                            </select>
                            <label>Enter Artical Quantity</label>
                            <input
                                className='admininput'
                                type="text"
                                name='productQuantity'
                                placeholder='Product Quantity'
                                required
                                value={formData.productQuantity}
                                onChange={handleChange}
                            />
                            <label>Enter Artical Discription</label>
                            <input
                                className='admininput'
                                type="text"
                                name='productDesc'
                                placeholder='Product Description'
                                required
                                value={formData.productDesc}
                                onChange={handleChange}
                            />
                            <label>Enter Artical Price</label>
                            <input
                                className='admininput'
                                type="number"
                                name='productPrice'
                                placeholder='Product Price'
                                required
                                value={formData.productPrice}
                                onChange={handleChange}
                            />
                            <label>Enter Artical Karat</label>
                            <input
                                className='admininput'
                                name='product_carat'
                                type="text"
                                placeholder='Enter Artical Karat'
                                value={formData.product_carat}
                                required
                                onChange={handleChange}
                            />
                            <label>Enter Weight of Artical</label>
                            <input
                                className='admininput'
                                name='product_weight'
                                type="text"
                                placeholder='Enter Weight of Artical'
                                value={formData.product_weight}
                                required
                                onChange={handleChange}
                            />
                            <label>Upload Main Image</label>
                            <input
                                className='admininput'
                                name='productMainImage'
                                type="file"
                                onChange={handleChange}
                            />
                            <label>Upload Image 1</label>
                            <input
                                className='admininput'
                                name='productImage1'
                                type="file"
                                onChange={handleChange}
                            />
                            <label>Upload Image 2</label>
                            <input
                                className='admininput'
                                name='productImage2'
                                type="file"
                                onChange={handleChange}
                            />
                            <label>Upload Image 3</label>
                            <input
                                className='admininput'
                                name='productImage3'
                                type="file"
                                onChange={handleChange}
                            />
                            <label>Discount in % :</label>
                            <input
                                className='admininput'
                                name='discount_percent'
                                type="number"
                                value={formData.discount_percent}
                                placeholder='Discount Percent'
                                onChange={handleChange}
                            />
                            <label>Last Date of Having Discount :</label>
                            <input
                                className='admininput'
                                name='discount_time'
                                type="date"
                                placeholder='Discount Last Date'
                                onChange={handleChange}
                            />
                            <label>Product Dilavery Time</label>
                            <input
                                className='admininput'
                                name='product_delivery_time'
                                type="number"
                                placeholder='Product Delivery Time'
                                value={formData.product_delivery_time}
                                required
                                onChange={handleChange}
                            />
                            <button className='adminbutton' type='submit'>Update</button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UpdateProductModal